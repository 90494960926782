import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import ReactPlayer from "react-player"
import { ParallaxBanner } from "react-scroll-parallax"
import Plx from "react-plx"
import "react-phone-number-input/style.css"
import dayjs from "dayjs"
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input"
import ReactCodeInput from "react-verification-code-input"

import Layout, { useAppState } from "../components/layout"
import SEO from "../components/seo"

import HeroVideo from "../videos/gameplay.mp4"
import Logo from "../images/logo-1.png"
import Icon1 from "../images/gaming.svg"
import Icon2 from "../images/joy.svg"
import Icon3 from "../images/platform.svg"

import Wrapper from "../components/wrapper"
import GridItem from "../components/griditem"
import GameItem from "../components/gameitem"

const Games = [
  {
    name: "Breakoutz",
    image: require("../images/breakzout.jpg"),
  },
  {
    name: "Gummy Blocks",
    image: require("../images/gummyblocks.jpg"),
  },
  {
    name: "Candy Forest",
    image: require("../images/candyforest.png"),
  },
  {
    name: "Circle Wheel",
    image: require("../images/CircleWheel.jpg"),
  },
  {
    name: "Car Crossing",
    image: require("../images/carcrossing.jpg"),
  },
  {
    name: "Hex Puzzle",
    image: require("../images/hexpuzzle.png"),
  },
]

const IndexPage = props => {
  const [loading, setLoading] = React.useState(false)
  const [user, setUser] = useState(false)
  const [phone, setPhone] = useState("")
  const [code, setCode] = useState(false)

  const authUser = data => {
    setLoading(true)
    return fetch(`/.netlify/functions/auth`, {
      body: JSON.stringify(data),
      method: "POST",
    }).then(response => {
      setLoading(false)
      return response.json()
    })
  }

  return (
    <Layout>
      <SEO title="Home" />
      <Plx
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 0,
          background: "#000",
          width: "100vw",
          height: "95vh",
          overflow: "hidden",
          top: 0,
          boxShadow: "0 10px 70px rgba(0,0,0,0.3)",
        }}
        parallaxData={[
          {
            start: "10vh",
            duration: "90vh",
            properties: [
              {
                startValue: 1,
                endValue: 0.8,
                property: "scale",
              },
            ],
          },
        ]}
      >
        <ReactPlayer
          style={{ width: "100%" }}
          width="100vw"
          height="95vh"
          url={HeroVideo}
          controls={false}
          playing
          loop
        />
        <div className="fullGradient" />
      </Plx>
      <Plx
        style={{ position: "relative", zIndex: 100 }}
        parallaxData={[
          {
            start: 0,
            duration: "200vh",
            properties: [
              {
                startValue: 0,
                endValue: -200,
                unit: "vh",
                property: "translateY",
              },
            ],
          },
        ]}
      >
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Wrapper
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "column",
              height: "80vh",
            }}
          >
            <img className="logo jump" src={Logo} alt="just2.games" />
            <h1
              style={{
                color: "#FFF",
                marginTop: 20,
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              Top Spiele für Browser & Smartphone
            </h1>
            <p
              style={{
                color: "#FFF",
                textAlign: "center",
                fontSize: "1.2rem",
                marginTop: "0.6rem",
                maxWidth: 400,
              }}
            >
              SPIELE JUST2GAMES SPIELE AUF DEINEM SMARTPHONE ODER PC!
            </p>
            {code === false ? (
              <PhoneInput
                placeholder="Handynummer eingeben"
                defaultCountry="DE"
                value={phone}
                onChange={value => setPhone(value)}
                style={{
                  marginTop: 30,
                  background: "#FFF",
                  borderRadius: 25,
                  padding: "5px 15px",
                }}
              />
            ) : (
              <ReactCodeInput
                onComplete={code => {
                  alert(code)
                }}
                className="CodeInput"
              />
            )}
            {user ? (
              <button
                className="button"
                style={{ margin: "1.2rem 0" }}
                onClick={() => {
                  navigate("/members")
                  // setLoading(true)
                  // window.open(
                  //   "https://mediator.kanzaloo.com/payment.jsf?serviceKey=f86f7ccb92df5143d779a041c7cd6af3&messageDigest=1433ac137bdaf2b3b1513d03f9b76700be57aa6066d4c6ab31f3e3b119352d06",
                  //   "_blank"
                  // )
                  // const phoneNumber = parsePhoneNumber(phone)
                  // setInterval(() => {
                  //   authUser({ phone: phoneNumber.number }).then(res => {
                  //     setLoading(true)
                  //     console.log({ res })
                  //     if (res.data.payed) {
                  //       navigate("/members")
                  //     }
                  //   })
                  // }, 5000)
                }}
              >
                {loading ? "Warte auf Bestätigung" : "Für €0.99 spielen"}
              </button>
            ) : (
              <button
                className="button"
                style={{ margin: "1.2rem 0" }}
                onClick={() => {
                  console.log({
                    parse: parsePhoneNumber(phone),
                  })
                  const phoneNumber = parsePhoneNumber(phone)
                  if (phoneNumber && phoneNumber.country === "DE") {
                    authUser({ phone: phoneNumber.number }).then(res => {
                      console.log({ res })
                      if (res.data.payed && res.data.purchaseEndTime) {
                        console.log(
                          dayjs().diff(dayjs(res.data.purchaseEndTime), "day")
                        )
                        if (
                          dayjs().diff(dayjs(res.data.purchaseEndTime), "day") >
                          7
                        ) {
                          navigate("/members")
                        } else {
                          setUser(true)
                        }
                      } else {
                        setUser(true)
                      }
                    })
                  } else {
                    alert("Keine valide deutsche Telefonnummer")
                  }
                }}
              >
                {loading
                  ? "Loading..."
                  : code === false
                  ? "Jetzt Anmelden"
                  : "Weiter"}
              </button>
            )}

            <p
              style={{ color: "#FFF", textAlign: "center", fontSize: "0.7rem" }}
            >
              KOSTEN: €0.99 PRO SPIEL: VOLLER ZUGANG ZU GEBUCHTEN SPIELEN AUF
              JUST2.GAMES. <br />
              MIT KLICK AUF "FÜR €0.99 SPIELEN" STIMME ICH AUSDRÜCKLICH DER
              SOFORTIGEN LEISTUNGSERBRINGUNG UND DEM VERLUST DES
              RÜCKTRITTSRECHTS ZU.
            </p>
          </Wrapper>
        </div>
      </Plx>

      <Wrapper padding>
        <div className="grid">
          <GridItem icon={Icon1} title="Spiele ohne Ende." color="#54C1BE" />
          <GridItem icon={Icon2} title="Keine Werbung." color="#D7DF20" />
          <GridItem
            icon={Icon3}
            title="Spiele auf allen Devices"
            color="#26AAE1"
          />
        </div>
      </Wrapper>
      <ParallaxBanner
        className="your-class"
        layers={[
          {
            image:
              "https://images.unsplash.com/photo-1564049489314-60d154ff107d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2561&q=80",
            amount: 0.2,
          },
        ]}
        style={{
          height: "50vh",
        }}
      >
        <h3
          style={{
            color: "#FFF",
            marginTop: 20,
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          Top Spiele für Browser & Smartphone
        </h3>
      </ParallaxBanner>

      <Wrapper padding>
        <h2 style={{ textAlign: "center", fontWeight: 500, width: "100%" }}>
          Unsere Spiele
        </h2>
        {Games.map((game, index) => {
          return (
            <GameItem
              key={`game-${index}`}
              title={game.name}
              desc={game.desc}
              image={game.image}
              reverse={index % 2}
              showPrice
            />
          )
        })}
      </Wrapper>

      {/* <Link to="/page-2/">Go to page 2</Link> */}
    </Layout>
  )
}

export default IndexPage
